











































import { mapActions, mapGetters } from 'vuex';
import { updateStateFeatureFlag } from '@/api/CopyStake/FeatureFlags';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { ECopyStakeFeatureFlag } from '@/store/modules/feature-flags/FeatureFlagNames';

export default {
  name: 'CopyStakeGamificationCenterJackpotTab',
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      isJackpotFeatureFlagEnabledProcessing: false
    };
  },
  computed: {
    ...mapGetters('FeatureFlags', [
      'isFeaturesLoading',
      'isEnabledFeatureFlag',
      'featureFlagIdByName'
    ]),
    jackpotFeatureFlagId(): number | null {
      return this.featureFlagIdByName(
        ECopyStakeFeatureFlag.GAMIFICATION_CENTER_JACKPOT
      );
    },
    isJackpotFeatureFlagEnabled(): boolean {
      return this.isEnabledFeatureFlag(
        ECopyStakeFeatureFlag.GAMIFICATION_CENTER_JACKPOT
      );
    }
  },
  methods: {
    ...mapActions('FeatureFlags', ['getOperatorCopyStakeFeatureFlags']),
    async handleClickIsJackpotFeatureFlagEnabledSwitch(): Promise<void> {
      if (
        this.isJackpotFeatureFlagEnabledProcessing ||
        !this.jackpotFeatureFlagId
      ) {
        return;
      }

      try {
        this.isJackpotFeatureFlagEnabledProcessing = true;

        await updateStateFeatureFlag(
          this.jackpotFeatureFlagId,
          !this.isJackpotFeatureFlagEnabled
        );
        await this.getOperatorCopyStakeFeatureFlags();
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isJackpotFeatureFlagEnabledProcessing = false;
      }
    }
  }
};
